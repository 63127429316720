<template>
  <ItemsListCard
    :items="items"
    :displayVariantsDialogAuto="displayVariantsDialogAuto"
    :isStockInfoAvailable="true"
  ></ItemsListCard>
</template>

<script>
import ItemsListCard from "@/components/ItemsListCard.vue";

export default {
  name: "CatalogSearchResults",
  components: { ItemsListCard },
  data() {
    return {
      items: [],
      itemsList: [],
      itemsIdentifiers: [],
      searchText: null,
      currentOffset: 0,
      limit: 50,
      displayVariantsDialogAuto: true,
    };
  },
  methods: {
    getSearchResults() {
      let flagIsVariant = false;
      let addItemToCart = false;

      this.$store.dispatch("loader/setLoading", true);
      if (this.searchText) {
        let searchTerm = this.searchText;
        let offset = this.currentOffset;
        let limit = this.limit;

        //Getting the Items which match the Search Term
        this.$store
          .dispatch("items/getItemsByExactSearch", {
            searchTerm,
            offset,
            limit,
          })
          .then(
            (result) => {
              //Iterating over the received results and verifying whether the Items are Parent Items or Variants
              //Depending on the Item being a Parent Item or a Variant, the "id" or the "parentItem" identifiers
              // are used to get the Item's characteristics and add them to the Items' list
              for (var i = 0; i < result.length; i++) {
                let itemId = null;
                if (result[i]["parentItem"]) {
                  itemId = result[i]["parentItem"];
                  flagIsVariant = true;
                } else {
                  itemId = result[i]["id"];
                  flagIsVariant = false;
                }

                // "resulti" stands for "result items"
                this.$store.dispatch("items/get", itemId).then((resulti) => {
                  if (!this.itemsIdentifiers.includes(resulti[0].id)) {
                    if (
                      flagIsVariant &&
                      this.currentOffset === 0 &&
                      result.length === 1 &&
                      resulti[0].data.variantsDetails.length > 0
                    ) {
                      //The Search Term represents a variant, therefore, the Dialog to choose the Variant's combination
                      //  must only display the options related to the searched Variant.
                      // Here the data.variants[X].values are filtered
                      // to only display the values which match with the variant resulti[0].data.variantsDetails[j]
                      for (var j = 0; j < resulti[0].data.variantsDetails.length; j++) {
                        if (
                          resulti[0].data.variantsDetails[j].code === this.searchText ||
                          resulti[0].data.variantsDetails[j].barcode === this.searchText &&
                            resulti[0].data.variantsDetails[j].status === 1
                        ) {
                          for (const key in resulti[0].data.variantsDetails[j].variants) {
                            resulti[0].data.variants[key].values = resulti[0].data.variants[key].values.filter(
                              (v) => v.value === resulti[0].data.variantsDetails[j].variants[key]
                            );
                          }
                          addItemToCart = true;
                          break;
                        }
                      }
                    }

                    if (resulti[0].status === 1) {
                      if (
                        result.length === 1 &&
                        Object.keys(resulti[0].data.variants).length === 0 &&
                        (!resulti[0].data.item_type || resulti[0].data.item_type !== 5) &&
                        (!resulti[0].data.menuLevels ||
                          (resulti[0].data.menuLevels && Object.keys(resulti[0].data.menuLevels).length === 0))
                      ) {
                        addItemToCart = true;
                      }
                      //When a single result is returned by the Search,
                      // the Item should be automatically added to the cart
                      if (addItemToCart) {
                        let itemId = result[0].id;
                        let parentItemId = result[0].parentItem;
                        this.$store.dispatch("cart/addToCart", { itemId, parentItemId });
                        this.displayVariantsDialogAuto = false;
                      } else {
                        this.displayVariantsDialogAuto = true;
                      }
                      this.itemsList.push(resulti[0]);
                      this.itemsIdentifiers.push(resulti[0].id);
                      this.currentOffset += 1;
                    }
                  }
                });
              }

              this.$store.dispatch("loader/setLoading", false);
              this.items = this.itemsList;
              //It is necessary to set the Search Term to null. Otherwise, when searching the same Item more than once,
              // with the aim of adding it to the Cart, the router push to the same route and with the same parameters
              // does not work. It throws an error, which is already being caught on the AppLayout,
              // and it does not perform the getSearchResults function.
              this.$route.query.search = null;
            },
            // eslint-disable-next-line
            (error) => {
              this.$store.dispatch(
                "alert/error",
                this.$vuetify.lang.t("$vuetify.catalogSearchResults.errorIdArticles")
              );
              this.$store.dispatch("loader/setLoading", false);
            }
          );
      } else {
        this.items = [];
        this.itemsList = [];
        this.itemsIdentifiers = [];
        this.currentOffset = 0;

        this.$store.dispatch(
          "alert/error",
          this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
            " " +
            this.$vuetify.lang.t("$vuetify.catalogSearchResults.errorNoSearch")
        );

        this.$store.dispatch("loader/setLoading", false);
      }
    },
    submitSearch: function (e) {
      if (e.keyCode === 13) {
        this.applySearch();
      }
    },
  },
  watch: {
    /**
     * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
     * To react to params changes in the same component, it is necessary to watch the $route object
     * @param to
     * @param from
     */
    $route(to, from) {
      var toSearch = to.query.search;
      var fromSearch = from.query.search;
      if (toSearch !== fromSearch) {
        this.items = [];
        this.itemsList = [];
        this.itemsIdentifiers = [];
        this.currentOffset = 0;
        this.searchText = toSearch;
        this.getSearchResults();
      }
    },
  },
  mounted() {
    this.searchText = this.$route.query.search;
    this.getSearchResults();

    window.onscroll = () => {
      if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
        // Try to find more results and put loading layer only if the number of
        // results is multiple of the limit
        if (this.items.length % this.limit === 0) {
          this.getSearchResults();
        }
      }
    };
  },
};
</script>

<style scoped></style>
